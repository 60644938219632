<script setup>
import { thumbor } from '@/Frontend/plugins/thumbor/thumbor'
import { vIntersectionObserver } from '@vueuse/components'
import { Head, Link, usePage } from '@inertiajs/vue3'
import { isClient, openDialogById, ratio } from '@/helpers'
import SearchWizard from '@components/SearchWizard.vue'
import SaleBanner from '@components/SaleBanner.vue'
import TourDialog from '@components/Tour/TourExplanationDialog.vue'

const props = defineProps({
	latestNews: {
		type: Array,
		default: () => [],
	},
	tipArticles: {
		type: Array,
		default: () => [],
	},
	spotlightArticles: {
		type: Array,
		default: () => [],
	},
	books: {
		type: Array,
		default: () => [],
	},
	recipes: {
		type: Array,
		default: () => [],
	},
	cities: {
		type: Array,
		default: () => [],
	},
	topTen: {
		type: Array,
		default: () => [],
	},
	svhRestaurants: {
		type: Array,
		default: () => [],
	},
	nierstichtingRestaurants: {
		type: Array,
		default: () => [],
	},
	euroToquesRestaurants: {
		type: Array,
		default: () => [],
	},
	allianceRestaurants: {
		type: Array,
		default: () => [],
	},
	promos: {
		type: Array,
		default: () => [],
	},
	instagramPosts: {
		type: Array,
		default: () => [{ title: 'none' }],
	},
})

const appStore = useAppStore()
const page = usePage()

// const isVisible = ref(false)

function onIntersectionObserver([{ isIntersecting }]) {
	appStore.homeWizardVisible = isIntersecting
}

onMounted(() => {
	// Open Tour, maybe also check if the user is logged in (andif the tour is closed already???)
	if (route().params?.tour === 'show' && page.props.auth.user) {
		// Setting a timeout causes the dialog nicer to show
		setTimeout(() => {
			openDialogById('tour-dialog')
		}, 100)
	}
})

const heroSectionImgMobileURL = thumbor(page.props.theme.main_image).filters('format(webp)').dimension(300, 250).url()
const heroSectionImgMobile = computed(() => {
	return `url('${heroSectionImgMobileURL}')`
})

const heroSectionImgTabletURL = thumbor(page.props.theme.main_image).filters('format(webp)').dimension(1200, ratio(1200, 21, 9)).url()
const heroSectionImgTablet = computed(() => {
	return `url('${heroSectionImgTabletURL}')`
})

const heroSectionImgDesktopURL = thumbor(page.props.theme.main_image).filters('format(webp)').dimension(1900, ratio(1900, 21, 9)).url()
const heroSectionImgDesktop = computed(() => {
	return `url('${heroSectionImgDesktopURL}')`
})

// Is there a better way to do this?
if (isClient && window.location.hash) {
	setTimeout(() => {
		const scrollEl = document.getElementById(window.location.hash.replace('#', ''))

		if (!scrollEl) return

		scrollEl.scrollIntoView({
			behavior: 'smooth',
		})
	}, 100)
}

const darkened = ref(false)

// Add SEO
const pageMeta = [
	{ tag: 'link', tagItem: 'rel', tagValue: 'canonical', contentItem: 'href', contentValue: `https://lekker.nl` },
	// { tag: 'link', tagItem: 'rel', tagValue: 'preload', contentItem: 'href', contentValue: heroSectionImgTablet.value },
	{ tag: 'meta', tagItem: 'name', tagValue: 'title', contentItem: 'content', contentValue: `Lekker` },
	{ tag: 'meta', tagItem: 'name', tagValue: 'description', contentItem: 'content', contentValue: `De restaurantgids van Nederland` },
	{ tag: 'meta', tagItem: 'property', tagValue: 'og:title', contentItem: 'content', contentValue: `Lekker` },
	{ tag: 'meta', tagItem: 'property', tagValue: 'og:description', contentItem: 'content', contentValue: `De restaurantgids van Nederland` },
	{ tag: 'meta', tagItem: 'property', tagValue: 'og:url', contentItem: 'content', contentValue: `https://lekker.nl` },
	{ tag: 'meta', tagItem: 'name', tagValue: 'twitter:card', contentItem: 'content', contentValue: 'summary' },
	{ tag: 'meta', tagItem: 'name', tagValue: 'twitter:site', contentItem: 'content', contentValue: '@lekker500' },
]
</script>
<template>
	<Head>
		<link
			v-for="url in [heroSectionImgMobileURL, heroSectionImgTabletURL, heroSectionImgDesktopURL]"
			:key="url"
			:href="url"
			rel="preload"
			as="image"
		/>
		<title>Home</title>
		<component
			:is="`${meta.tag}`"
			v-for="(meta, metaIndex) in pageMeta"
			:key="`pagemeta-${metaIndex}`"
			:[meta.tagItem]="meta.tagValue"
			:[meta.contentItem]="meta.contentValue"
		/>
	</Head>

	<main>
		<section
			class="hero-section pt-4 pt-md-0 mb-5 mb-lg-0"
			:class="{
				darkened: darkened,
			}"
		>
			<div class="hero-section-contents">
				<div class="mx-4 pb-3">
					<h1
						class="ff-serif lh-1 text-white"
						style="text-shadow: 0px 2px rgba(0, 0, 0, 0.2)"
					>
						Pre-order nu de 2025 editie!
					</h1>
					<p
						class="fs-6 fs-lg-5 fw-medium text-white"
						style="text-shadow: 0px 2px rgba(0, 0, 0, 0.2)"
					>
						Betaal geen verzendkosten, en krijg direct digitale toegang tot al onze premium content van voorgaande jaren!
					</p>
					<Link
						role="button"
						:href="route('preorder')"
						class="btn btn-theme-500 btn-sm-sm fw-bold py-2"
					>
						{{ $t('Preorder now') }}
					</Link>
				</div>
				<client-only>
					<search-wizard
						v-intersection-observer="onIntersectionObserver"
						:cancel-text="$t('Reset')"
						:restaurant-properties="page.props.restaurantProperties"
						is-inline
						class="shadow p-3 p-md-4 mx-2 mx-lg-4 mb-n5 mb-lg-0"
						@mouseover="darkened = true"
						@mouseleave="darkened = false"
					/>
				</client-only>
			</div>
		</section>

		<div class="container-xxl main-wrapper">
			<div class="row">
				<section class="col-12 my-3">
					<ul class="list-inline row">
						<li
							v-for="(usp, uspIndex) in page.props.theme.usp"
							:key="`usp-${uspIndex}`"
							class="col-lg-4 text-lg-center"
						>
							<i class="str-check me-3" />
							<span class="fw-medium fs-7">{{ $t(usp) }}</span>
						</li>
					</ul>
				</section>
			</div>
		</div>

		<div class="container-xxl main-wrapper">
			<div class="row">
				<div class="col-12 mb-5">
					<sale-banner />
				</div>
			</div>
		</div>

		<!-- Top 500 -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			title="Top 500 restaurants"
			:items="topTen"
			:columns="{
				xs: 12,
				md: 4,
			}"
			url="/restaurant/klassering"
			class="position-relative mb-5"
		>
			<theme-card-restaurant
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
				class="w-100"
			>
				<template #img="{ meta }">
					<img
						:src="slotProps.item.images.length ? $thumbor(slotProps.item.images[0].url).dimension(350, ratio(416, 1, 0.75)).filters('format(webp)').url() : ''"
						class="custom-card-img image-fluid w-100"
						loading="lazy"
						:alt="slotProps.item.name"
						onerror="handleImgFallback(this);"
					/>
				</template>
			</theme-card-restaurant>
		</theme-base-swiper>

		<!-- Latest news -->
		<theme-base-swiper
			v-if="latestNews.length"
			v-slot="slotProps"
			tag="section"
			:title="$t('Latest news')"
			:items="latestNews"
			:columns="{
				xs: 6,
				md: 4,
				lg: 3,
			}"
			url="/nieuws"
			class="mb-5"
		>
			<theme-card-plain
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
			>
				<template #img="{ meta }">
					<thumbor-image
						:use="['md', 'lg']"
						:dimensions="{
							md: [250, 150],
							lg: [306, 230],
						}"
						:img="meta.imgSource"
						class="w-100 mb-0"
						:classes="{
							img: 'custom-card-img image-fluid bg-neutral-300 w-100',
						}"
						:crop-preset="meta.imgCropping"
						:img-alt="meta.imgAlt"
						filters="format(webp)"
					/>
				</template>
			</theme-card-plain>
		</theme-base-swiper>

		<!-- Spotlight -->
		<theme-base-swiper
			v-if="spotlightArticles.length"
			v-slot="slotProps"
			tag="section"
			title="Uitgelicht"
			:items="spotlightArticles"
			:columns="{
				xs: 12,
				md: 6,
			}"
			class="content-visibility-auto mb-5"
		>
			<theme-card-highlight
				tag="article"
				:item="slotProps.item"
				class="w-100"
			>
				<template #img="{ meta }">
					<thumbor-image
						:use="['md', 'lg']"
						:dimensions="{
							md: [450, ratio(450)],
							lg: [320, 230],
						}"
						:img="meta.imgSource"
						class="w-100 h-100 mb-0"
						:classes="{
							img: 'custom-card-img bg-nuetral-300 w-100',
						}"
						:crop-preset="meta.imgCropping"
						:img-alt="meta.imgAlt"
						filters="format(webp)"
					/>
				</template>
			</theme-card-highlight>
		</theme-base-swiper>

		<!-- SVH -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			title="SVH Meester werkzaam"
			:items="svhRestaurants"
			:columns="{
				xs: 12,
				md: 4,
			}"
			url="/results?ps[]=20&ps[]=23&ps[]=26&ps[]=29"
			class="content-visibility-auto position-relative mb-5"
		>
			<theme-card-restaurant
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
				class="w-100"
				:partnership-show="['svh-meesterkok', 'svh-meestergastheer', 'svh-meestergastvrouw', 'svh-wijnmeester']"
			>
				<template #img="{ meta }">
					<img
						:src="slotProps.item.images.length ? $thumbor(slotProps.item.images[0].url).dimension(350, ratio(416, 1, 0.75)).filters('format(webp)').url() : ''"
						class="custom-card-img image-fluid w-100"
						loading="lazy"
						:alt="slotProps.item.name"
						onerror="handleImgFallback(this);"
					/>
				</template>
			</theme-card-restaurant>
		</theme-base-swiper>

		<!-- Tips -->
		<theme-base-swiper
			v-if="tipArticles.length"
			v-slot="slotProps"
			tag="section"
			:title="$t('Our tips')"
			:items="tipArticles"
			:columns="{
				xs: 6,
				md: 3,
				lg: 2,
			}"
			url="/tips"
			class="content-visibility-auto mb-5"
		>
			<theme-card-tip
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
			>
				<template #img="{ meta }">
					<thumbor-image
						:use="['md']"
						:dimensions="{
							md: [250, ratio(250, 1, 0.7)],
						}"
						:img="meta.imgSource"
						class="w-100 mb-2"
						:classes="{
							img: 'custom-card-img image-fluid rounded-bottom bg-neutral-300 w-100',
						}"
						:crop-preset="meta.imgCropping"
						:img-alt="meta.imgAlt"
						filters="format(webp)"
					/>
				</template>
			</theme-card-tip>
		</theme-base-swiper>

		<!-- Nierstichting -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			title="Aangesloten bij de Nierstichting"
			:items="nierstichtingRestaurants"
			:columns="{
				xs: 12,
				md: 4,
			}"
			url="/results?ps[]=17"
			class="content-visibility-auto position-relative mb-5"
		>
			<theme-card-restaurant
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
				class="w-100"
			>
				<template #img="{ meta }">
					<img
						:src="slotProps.item.images.length ? $thumbor(slotProps.item.images[0].url).dimension(350, ratio(416, 1, 0.75)).filters('format(webp)').url() : ''"
						class="custom-card-img image-fluid w-100"
						loading="lazy"
						:alt="slotProps.item.name"
						onerror="handleImgFallback(this);"
					/>
				</template>
			</theme-card-restaurant>
		</theme-base-swiper>

		<!-- Advertising -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			:items="promos"
			title="Tè Lekker!"
			:columns="{
				xs: 12,
				md: 4,
				lg: 3,
			}"
			class="content-visibility-auto mb-5"
		>
			<div
				class="bg-neutral-300 rounded position-relative"
				style="aspect-ratio: 16 / 9"
			>
				<img
					:src="$thumbor(slotProps.item.img).filters('format(webp)').url()"
					class="img-fluid w-100 rounded border border-neutral-300"
					loading="lazy"
					width="500"
					height="281"
					alt="Sale mockup"
				/>
				<a
					class="stretched-link"
					aria-label="Lees meer"
					:href="slotProps.item.url"
				></a>
			</div>
		</theme-base-swiper>

		<!-- Euro-Toques -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			title="Aangesloten bij Euro-Toques"
			:items="euroToquesRestaurants"
			:columns="{
				xs: 12,
				md: 4,
			}"
			url="/results?ps[]=14"
			class="content-visibility-auto position-relative mb-5"
		>
			<theme-card-restaurant
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
				class="w-100"
			>
				<template #img="{ meta }">
					<img
						:src="slotProps.item.images.length ? $thumbor(slotProps.item.images[0].url).dimension(350, ratio(416, 1, 0.75)).filters('format(webp)').url() : ''"
						class="custom-card-img image-fluid w-100"
						loading="lazy"
						:alt="slotProps.item.name"
						onerror="handleImgFallback(this);"
					/>
				</template>
			</theme-card-restaurant>
		</theme-base-swiper>

		<!-- Recipes -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			:title="$t('Recipes')"
			:items="recipes"
			:columns="{
				xs: 6,
				md: 3,
				lg: 2,
			}"
			url="/recepten"
			class="content-visibility-auto mb-5"
		>
			<theme-card-recipe
				tag="article"
				:item="slotProps.item"
			>
				<template #img="{ meta }">
					<thumbor-image
						:use="['sm', 'md']"
						:dimensions="{
							sm: [220, ratio(220, 1, 1.35)],
							md: [240, ratio(220, 1, 1.35)],
						}"
						:img="meta.imgSource"
						class="w-100 mb-0"
						:classes="{
							img: 'custom-card-img image-fluid bg-neutral-300 w-100',
						}"
						:img-alt="meta.imgAlt ?? null"
						:crop-preset="meta.imgCropping"
						filters="format(webp)"
					/>
				</template>
			</theme-card-recipe>
		</theme-base-swiper>

		<!-- City Guide -->
		<!-- <theme-base-swiper
			id="city-guide"
			v-slot="slotProps"
			tag="section"
			:items="cities"
			title="City Guide"
			:columns="{
				xs: 12,
				md: 4,
				lg: 3,
			}"
			class="mb-5"
			:equal-slide-height="false"
		>
			<theme-card-city-guide
				tag="article"
				:item="slotProps.item"
			/>
		</theme-base-swiper> -->

		<!-- Alliance Gastronomique -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			title="Aangesloten bij Alliance Gastronomique"
			:items="allianceRestaurants"
			:columns="{
				xs: 12,
				md: 4,
			}"
			url="/results?ps[]=2"
			class="content-visibility-auto position-relative mb-5"
		>
			<theme-card-restaurant
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
				class="w-100"
			>
				<template #img="{ meta }">
					<img
						:src="slotProps.item.images.length ? $thumbor(slotProps.item.images[0].url).dimension(350, ratio(416, 1, 0.75)).filters('format(webp)').url() : ''"
						class="custom-card-img image-fluid w-100"
						loading="lazy"
						:alt="slotProps.item.name"
						onerror="handleImgFallback(this);"
					/>
				</template>
			</theme-card-restaurant>
		</theme-base-swiper>

		<!-- Video -->
		<!-- <theme-base-swiper
			v-slot="slotProps"
			tag="section"
			:title="$t('Video\'s')"
			:items="latestNews"
			:columns="{
				xs: 6,
				md: 4,
				lg: 3,
			}"
			url="/nieuws"
			class="content-visibility-auto mb-5"
		>
			<theme-card-plain
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
				video
			>
				<template #img="{ meta }">
					<thumbor-image
						:use="['md', 'lg']"
						:dimensions="{
							md: [250, 150],
							lg: [306, 230],
						}"
						:img="meta.imgSource"
						class="w-100 mb-0"
						:classes="{
							img: 'custom-card-img image-fluid bg-neutral-300 w-100',
						}"
						:crop-preset="meta.imgCropping"
						:img-alt="meta.imgAlt"
						filters="format(webp)"
					/>
				</template>
			</theme-card-plain>
		</theme-base-swiper> -->

		<!-- Tips 2 -->
		<!-- <theme-base-swiper
			v-slot="slotProps"
			tag="section"
			title="Misschien ook interessant"
			:items="tipArticles"
			:columns="{
				xs: 6,
				md: 3,
				lg: 2,
			}"
			url="/tips"
			class="content-visibility-auto mb-5"
		>
			<theme-card-tip
				tag="article"
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
			>
				<template #img="{ meta }">
					<thumbor-image
						:use="['md']"
						:dimensions="{
							md: [250, ratio(250, 1, 0.7)],
						}"
						:img="meta.imgSource"
						class="w-100 mb-2"
						:classes="{
							img: 'custom-card-img image-fluid rounded-bottom bg-neutral-300 w-100',
						}"
						:crop-preset="meta.imgCropping"
						:img-alt="meta.imgAlt"
						filters="format(webp)"
					/>
				</template>
			</theme-card-tip>
		</theme-base-swiper> -->

		<!-- Instagram -->
		<theme-base-swiper
			v-if="instagramPosts.length"
			v-slot="slotProps"
			tag="section"
			title="Instagram"
			:items="instagramPosts"
			:columns="{
				xs: 12,
				md: 4,
				lg: 3,
			}"
			url="https://www.instagram.com/lekker500"
			link-text="Follow us"
			link-target="_blank"
			class="content-visibility-auto mb-5"
		>
			<theme-card-instagram
				:item="slotProps.item"
				style="--card-aspect-ratio: 1 / 0.75"
				class="bg-white"
			>
				<!-- <template #img="{ meta }">
					<thumbor-image
						:use="['md', 'lg']"
						:dimensions="{
							md: [250, 150],
							lg: [306, 230],
						}"
						:img="meta.imgSource"
						class="w-100 mb-0"
						:classes="{
							img: 'custom-card-img image-fluid bg-neutral-300 w-100',
						}"
						:crop-preset="meta.imgCropping"
						:img-alt="meta.imgAlt"
						filters="format(webp)"
					/>
				</template> -->
			</theme-card-instagram>
		</theme-base-swiper>

		<!-- Books -->
		<theme-base-swiper
			v-slot="slotProps"
			tag="section"
			:title="$t('Books')"
			:items="books"
			:columns="{
				xs: 6,
				md: 3,
				xl: 2,
			}"
			url="/boeken"
			class="content-visibility-auto mb-5"
		>
			<theme-card-book
				:item="slotProps.item"
				tag="article"
			/>
		</theme-base-swiper>
	</main>

	<tour-dialog v-if="route().params?.tour === 'show'" />
</template>

<style lang="scss">
.hero-section {
	background-repeat: no-repeat;
	background-size: cover;
	background-image: v-bind(heroSectionImgMobile);

	@media screen and (min-width: 768px) {
		background-image: v-bind(heroSectionImgTablet);
	}

	@media screen and (min-width: 1200px) {
		background-image: v-bind(heroSectionImgDesktop);
	}
}
</style>
