<script setup>
import { Link } from '@inertiajs/vue3'
const appStore = useAppStore()
</script>

<template>
	<div class="d-lg-flex flex-lg-row align-items-center text-center text-lg-start gap-3 text-bg-accent-100 rounded shadow-sm p-4">
		<img
			:srcset="`
                ${$thumbor($page.props.theme.premium_teaser_image).dimension(150, 0).filters('format(webp)').url()} 150w,
                ${$thumbor($page.props.theme.premium_teaser_image).dimension(175, 0).filters('format(webp)').url()} 175w
            `"
			:sizes="`
                (max-width: ${appStore.breakpoints.md}px) 150px,
                175px
            `"
			alt="magazine mockup"
			loading="lazy"
			width="150"
		/>
		<div class="flex-grow-1">
			<div class="d-lg-flex flex-lg-grow-1 gap-2 mt-3 mb-2 my-lg-0">
				<h4 class="fs-4 fw-bold d-inline">Pre-order nu de 2025 editie!</h4>
			</div>
			<p class="fs-6 fs-lg-5 mb-lg-0">Betaal geen verzendkosten, en krijg direct digitale toegang tot al onze premium content van voorgaande jaren!</p>
		</div>

		<Link
			:href="route('preorder')"
			target="_blank"
			class="btn btn-white fw-bold"
			style="flex: 0 0 auto"
		>
			Pre-order nu!
		</Link>
	</div>
</template>
