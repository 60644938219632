<script setup>
import { closeAllDialogs } from '@/helpers'
// import TourSlide from './TourSlide.vue'
import { ratio } from '@/helpers'

const tourSlide = defineAsyncComponent(() => import('./TourSlide.vue'))
const stepsSlider = ref(null)

const totalSlides = ref(0)
const currentSlide = ref(1)
const isBeginning = ref(true)
const isEnd = ref(false)

onMounted(() => {
	// console.dir(stepsSlider.value)

	stepsSlider.value.swiper.on('slideChange', function () {
		currentSlide.value = this.realIndex + 1
		isBeginning.value = this.isBeginning
		isEnd.value = this.isEnd
	})

	// stepsSlider.value.swiper.on('init', function () {
	// 	console.log(this)
	// })
})
</script>

<template>
	<dialog
		id="tour-dialog"
		ref="tourDialog"
		class="dialog-modal dialog-modal-bottom-sm shadow"
		style="overflow: visible; --lek-modal-max-width: 536px"
		aria-hidden="true"
		data-use-native-locking="true"
	>
		<div class="modal-content">
			<div
				class="modal-body p-0"
				style="overflow: visible"
			>
				<swiper-container
					ref="stepsSlider"
					:navigation="{
						prevEl: '#tour-dialog .btn-prev',
						nextEl: '#tour-dialog .btn-next',
					}"
					effect="fade"
				>
					<div slot="container-start">
						<div class="position-absolute z-3 w-100 d-flex justify-content-end p-3">
							<i
								role="button"
								:aria-label="$t('Close tour')"
								class="str-close text-white"
								@click="closeAllDialogs()"
							/>
						</div>
					</div>
					<swiper-slide
						style="height: auto"
						@vue:mounted="totalSlides++"
					>
						<tour-slide
							:img="$thumbor('beheer.lekker.nl/source/lekker/beheer/restaurant_fotos/2022/11/1669654101qiMyLkTQ9y.jpg').dimension(536, 225).filters('format(webp)').url()"
							current-slide="1"
							:total-slides="totalSlides"
							:subject-text="$t('Welcome')"
						>
							<div class="h3 ff-serif mb-3">{{ $t('Welcome to Lekker.nl') }}</div>
							<p>{{ $t("Great that you've become a member of the culinary club of the Netherlands. On this website, you'll find everything about the latest news and developments in the culinary scene.") }}</p>
						</tour-slide>
					</swiper-slide>
					<swiper-slide
						style="height: auto"
						@vue:mounted="totalSlides++"
					>
						<tour-slide
							:img="$thumbor('beheer.lekker.nl/source/lekker/beheer/restaurant_fotos/2019/10/1539089770vyNbJXJQm3.jpg').dimension(536, 225).filters('format(webp)').url()"
							current-slide="2"
							:total-slides="totalSlides"
							:subject-text="$t('Reviews')"
						>
							<div class="h3 ff-serif mb-3">{{ $t('Independent and objective') }}</div>
							<p>{{ $t('Every year, we visit hundreds of restaurants with our anonymous reporting team to compile a list of the top 500 addresses.') }}</p>
							<p>{{ $t("On our website and in the printed guide, you'll read our objective reviews and see the ranking of the restaurants. This way, you always know what to expect.") }}</p>
						</tour-slide>
					</swiper-slide>
					<swiper-slide
						style="height: auto"
						@vue:mounted="totalSlides++"
					>
						<tour-slide
							:img="true"
							current-slide="3"
							:total-slides="totalSlides"
							:subject-text="$t('Search')"
						>
							<template #img>
								<video
									v-if="currentSlide === 3"
									loop
									poster="https://cdn.pijper.io/core/mockups/lek-search-2-poster.png"
									class="w-100"
								>
									<source
										src="https://cdn.pijper.io/core/mockups/lek-search-2.mp4"
										type="video/mp4"
									/>
									Your browser does not support the video tag.
								</video>
								<!-- <video
									class="lazyload"
									preload="none"
									data-autoplay
									data-loop
									data-poster="https://cdn.pijper.io/core/mockups/lek-search-2-poster.png"
									src="https://cdn.pijper.io/core/mockups/lek-search-2.mp4"
								>
									Your browser does not support the video tag.
								</video> -->
							</template>
							<div class="h3 ff-serif mb-3">{{ $t('Search and find the best restaurants') }}</div>
							<p>{{ $t('The easiest search engine to find the best restaurant for you. With the keywords where, what, and how, we help you find the ideal restaurant.') }}</p>
							<p>{{ $t('Want to be surprised? Use our predefined searches or check out one of our many tip lists.') }}</p>
						</tour-slide>
					</swiper-slide>
				</swiper-container>
			</div>
			<div class="modal-footer justify-content-start gap-3 px-md-4">
				<button
					class="btn btn-outline-neutral-100 text-body border-neutral-900 fw-bold m-0"
					:aria-label="$t('Close tour')"
					@click="closeAllDialogs()"
				>
					{{ $t('Close tour') }}
				</button>
				<button
					v-show="!isBeginning"
					class="btn btn-theme-500 fw-bold flex-grow-1 m-0 btn-prev"
				>
					<i class="str-arrow-left me-1"></i>
					<span class="d-none d-md-inline">{{ $t('Previous') }}</span>
				</button>
				<button
					v-show="!isEnd"
					class="btn btn-theme-500 fw-bold flex-grow-1 m-0 btn-next"
				>
					<span class="d-none d-md-inline">{{ $t('Next') }}</span>
					<i class="str-arrow-right ms-1"></i>
				</button>
			</div>
		</div>
	</dialog>
</template>
